.main-navbar{
  background-color: hsl(200, 92%, 51%);
}

.web-navbar {
  height: 54px !important;
  background-color: #0f6492;
}

.dropdown {
  float: right;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18pt;
}

.stars-total {
  font-size: 18pt;
  color: white !important;
  font-family:Arial, Helvetica, sans-serif;
}

.close-button {
  all: unset;
  background-color: #0f6492;
}

#icons {
  color: white;
}

.lesson-info {
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  position: absolute;
  width: fit-content;
}

.assignment-panel {
  color: white;
  background-color: white;
}

.about-modal {
  text-align: center;
}

.about-team-info {

}

.message {
  text-align: start;
}

.about-buttons {
  background-color: #0f6492 !important;
  margin: 5px;
}

.navbar-text {
  font-size: 20px;
  padding-top: 23px;
  font-weight: 600;
}