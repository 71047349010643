.course-info {
  justify-content: center;
}

.course-details {
  font-family: 'Courier New', Courier, monospace;
  font-size: 22px;
}

.roster-button {
  width: 100%;
  margin: 5px 0px 5px 0px;
}

.link {
  color: blue;
  border-radius: 50%;
  text-decoration: underline;
}

.students-spinner {
  text-align: center;
}

.download-button {
  height: 100%;
}

.date-time {
  padding: 6px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}