.dashboard {
    text-align: center;
}

.dashboard-items {
    display: inline-block;
    padding: 11px;
    background-color: #e4f5ff;
    border: none;
}
/* 
.dashboard-items:focus {
    outline: none !important;
    outline-offset: none !important;
} */

.ciruclar-progress-bar {
    width: 200px;
}

.text {
    font-size: 12.8pt;
    font-weight: 500;
}

.assignment-task {
    width: 30%;
}

.lecture-button {
    background-color: #0f6492;
    border-radius: 24px;
    width: 100%;
    margin: 10px;
    width: 50%;
}

.mobile-app-link {
  background-color: transparent;
  border: none;
  margin: 5px 0px 5px 0px;
}

.mobile-app-link:focus,.mobile-app-link:active {
  outline: none !important;
  box-shadow: none !important;
}

.mobile-app-link:hover {
  background-color: transparent;
  border-color: transparent;
}

.progress-bar-inner-text {
  font-size: 35px;
  color: #6abd32;
}

.progress-bar-completed-text {
  font-size: 35px;
  color: black;
}