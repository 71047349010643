.intro-block-half {
    width: 50%;
    text-align: center;
}

.intro-block-half-item {
    margin: 15px 15px 15px 0px;
}

.intro-block-third {
    width: 33%;
    text-align: center;
}

.intro-block-third-item {
    margin: 15px 15px 15px 0px;
}

.intro-block-full {
    text-align: center;
    width: 100%;
}

.intro-block-full-item {
    width: 100%;
}

.intro-block-title {
    text-align: center;
    justify-content: center;
}

.content-img {
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
}

.test-question-banner {
  position: absolute;
  z-index: 30;
  background-color: #97d170;
  color: black;
  opacity: 0.9;
  padding: 5px;
}

.center-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #31b2f6;
}