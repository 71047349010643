.feedback-buttons {
  width: 100%;
  display: inline-flex;
  position: absolute;
  left: 0;
  bottom: 0;
}

.feedback-button {
    border: none;
    margin: 5px;
    width: 100%;
    height: 25px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .4);
    font-weight: bold;
    color: white;
    line-height: 1px;
}

.feedback-button:hover {
  color: black;
}

.stars {
  position: absolute;
  top: 125px;
  right: 197px;
}

.percentages {
  margin: 10px;
}

#chart {
  margin-top: 10px;
}