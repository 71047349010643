.main-container {
    background-color: hsl(200, 92%, 51%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-width {
  width: 300px;
}

.signup-text {
  color: white;
  text-align: center;
  padding: 20px 0px 0px 0px
}