.modal-container-mobile {
  position: absolute;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  overflow-y: auto;
}

.modal-container-web {
  position: absolute;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  overflow-y: auto;
}