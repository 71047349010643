input[type="file"] {
    display: none;
}
.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: white;
}

.input-file {
    width: 75%;
}

.input-error {
    width: 75%;
    color: red;
}

.csv-import {
    width: 100%;
    margin: 5px 0px 5px 0px;
}

.csv-modal-title {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.csv-modal-contents {
    margin: 20px;
}

.csv-modal-input {
    width: 100%;
}

.csv-modal-item {
    margin-bottom: 20px;
}

.csv-modal-indent {
    text-indent: 35px;
}
