.modal {
    background: rgba(0, 0, 0, 0.6);
}

.modal-close {
    float: right;
    color: black;
    font-size: larger;
}

.modal-container {
    position: absolute;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    height: 475px;
    width: 950px;
    overflow-y: auto;
}

.grading-modal {
    position: absolute;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    height: 400px;
    width: 500px;
    overflow-y: auto;
}

.submit {
    float: right;
    margin: 5px 0px 5px 0px;
}