.navbar {
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.smaller-screen-menu-btn {
  position: absolute;
  top: 25%;
  left: 285%;
  z-index: 30;
  background-color: #0f6492;
  border-radius: 24px;
  height: 44px;
  line-height: 1px;
  width: 50px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .4);
}

.nav-menu {
    display: none;
    background-color: #0f6492;;
    width: 275px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    overflow-y: auto;
    z-index: 30;
    -moz-box-shadow:    0 0 10px #000000;
    -webkit-box-shadow: 0 0 10px #000000;
    box-shadow:         0 0 10px #000000;
}

.nav-menu.active {
    display: block;
    background-color: #eeeeee;
    right: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none; 
    height: 150px;
}

.intro-text {
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: black;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #e4f5ff;
}

.nav-menu-items {
    width: 100%;
    padding: 0;
}

.navbar-toggle {
    background-color: #0f6492;
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 30;
}

span {
    margin-left: 16px;
}

.content-image {
    height: 100px;
    width: 150px;
    margin-left: 20px;
    background: white;
    border-color: #6abd32 !important;
}