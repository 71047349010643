.delete-button {
    float: right;
    border-radius: 50%;
}

.student-active {
    color: blue;
    float: right;
    border-radius: 50%;
    text-decoration: underline;
}

.student-inactive {
    color: red;
    float: right;
    border-radius: 50%;
}

.submit {
    float: right;
    margin: 5px 0px 5px 0px;
}

input[type='radio'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: 3px solid hsl(200, 92%, 51%);
    margin: 0px 10px 0px 40px;
    border-width: thin;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

input[type="radio"]:checked:before {
    background: hsl(200, 92%, 51%);
    
}

input[type="radio"]:checked {
  border-color: hsl(200, 92%, 51%);
}

#table-index {
    padding-top: 45px;
}

#username-text {
    padding-top: 30px;
}

.button-style {
    background-color: hsl(103, 79%, 47%);
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .4);
    font-weight: bold;
}

.button-cancel {
    background-color: red;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .4);
    font-weight: bold;
}

.self-enrollment-text {
    padding-top: 20px;
}

.add-button {
    width: 100%;
}

.toast-message {
  max-width: 50% !important;
  float: right;
  position: absolute !important;
  right: 0;
  bottom: 0;
  background-color: rgb(15, 168, 245);
  color: white;
}