.payment-container {
  background-color: #F0EFF4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.form-container {
  border-radius: 8px;
  width: 450px;
  background-color: #F5F5F5;
  overflow: hidden;
  -webkit-box-shadow: -1px 10px 18px -4px rgba(189,189,189,1);
  -moz-box-shadow: -1px 10px 18px -4px rgba(189,189,189,1);
  box-shadow: -1px 10px 18px -4px rgba(189,189,189,1);
}
.course-name-container {
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(200, 92%, 51%);
}
.course-name {
  font-size: 16;
  font-weight: bold;
  color: white;
}
.course-name-description {
  font-size: 15px;
  margin-top: -7px;
}
.logo {
  position: absolute;
  right: 8px;
  width: 30px;
}
.card-info-container {
  padding: 30px;
}
.card-info {
  font-size: 15px;
  color: #4A4A4A;
  margin-bottom: 10px;
}
.card-number-container {
  padding: 8px;
  border: 1px solid #CFCFCF;
  border-radius: 4px;
  background-color: white;
}
.pay-button {
  background-color: hsl(200, 92%, 51%);
  color: white;
  width: 100%;
  font-weight: bold;
  border: 1px solid hsl(200, 92%, 51%);
}
.pay-button:hover {
  background-color: rgb(18, 143, 197);
  border: 1px solid rgb(18, 143, 197);
}

.apply-coupon-btn {
  margin: 0 0 0 10px !important
}