.circle {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stroke-container {
    display: flex;
    height: 40px;
    align-items: center;
}

.stroke {
    width: 60px;
    height: 4px;
}

.checkpoint-title {
    position: absolute;
    text-transform: capitalize;
    white-space: nowrap;
}

.assignments-container {
    background-color: #FAFAFA;
    padding: 10px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -2px rgba(0,0,0,0.62);
    -moz-box-shadow: 0px 4px 8px -2px rgba(0,0,0,0.62);
    box-shadow: 0px 4px 8px -2px rgba(0,0,0,0.62);
}

.text-decoration {
    text-decoration: underline;
}

.flex-1 {
    flex: 1;
}

.plus-circle {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid black; 
}

.button-no-style {
    background-color: transparent;
    border-color: transparent;
}

.button-no-style:hover {
    background-color: transparent;
    border-color: transparent;
}

.button-no-style:active {
    background-color: transparent!important;
    border-color: transparent!important;
}

.preset-assignment-sets {
    background-color: #E3F6FF;
}

ul {
    list-style-type: none;
}

.assign-warning {
    color: red;
}

.assigned-courses {
    color: black;
    width: 25%;
}

.confirm-button {
    float: right;
}

.assigned-to {
    display: -webkit-inline-box;
    width: 100%;
}

.navigation {
    display:block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.button-save {
    margin-left: auto;
    margin-right: 15px;
}

.checkbox label {
  font-size: 14pt;
  margin-left: 10px;
}

.checkbox input {
  width: 1.2rem;
  height: 1.2rem;
}

.assignments-font-size {
  font-size: 14pt;
}